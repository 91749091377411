::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: rgba(#1596dd, 0.8);
}

::-webkit-scrollbar-thumb:hover {
	background: #1596dd;
}

* {
	box-sizing: border-box;
}
.version {
	white-space: nowrap;
}
.flex-wrapper {
	display: flex;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
}

a {
	text-decoration: none;
	color: #000;
}

img {
	max-width: 100%;
	height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	line-height: 1;
}

.container {
	max-width: 1270px;
	margin: 0 auto;
	padding: 30px;
	width: 100%;
}

.el- {
	&tabs {
		&--top {
			max-width: 800px;
			margin: 0 auto;
			width: 100%;
		}
		&__content {
			.el-tab-pane {
				padding: 10px;
			}
		}
	}
	&dropdown {
		position: sticky;
		top: 150px;
		&-menu__item {
			&.active {
				color: var(--el-dropdown-menuItem-hover-color);
			}
		}
	}
}
.el-form-item .option_popup {
	position: absolute;
	bottom: -42px;
	left: 0;
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	padding: 5px 20px;
	z-index: 2;
	transition: all 0.2s ease;
	opacity: 0;
}
.el-form-item:has(label.el-checkbox.is-checked) .el-form-item__label::before,
.el-form-item:has(label.el-checkbox.is-checked) label:not(label.el-checkbox.is-checked)::before {
	content: "\2713";
	width: 12px;
	font-size: 16px;
	font-weight: 900;
	color: #1596dd;
	padding-right: 5px;
	margin-left: -16px;
	height: 16px;
	display: inline-block;
}
.el-form-item:hover .option_popup {
	pointer-events: all;
	opacity: 1;
}

.section-title {
	font-size: 32px;
	text-align: center;
}

input,
textarea,
button {
	font-family: "Poppins", sans-serif;
	border: none;
	background: none;
	box-shadow: none;
	padding: 0;
	margin: 0;
}

textarea {
	resize: none;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 24px;
}

p {
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.5px;
}

@media screen and (max-width: 1080px) {
	.el- {
		&dropdown {
			position: static;
		}
	}
}
@media screen and (max-width: 500px) {
	.container {
		padding: 15px;
	}
	.el-tabs__content .el-tab-pane {
		padding: 5px;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 50px;
	height: 80px;
	background: #fff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
}

.logo {
	font-size: 24px;
	font-weight: 700;
	transition: 0.2s all;
	display: flex;
	align-items: center;
}

.logo img {
	width: 200px;
}

.logo:hover span,
.logo:hover {
	color: #1596dd;
}

.menu {
	list-style-type: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0;
	margin: 0;
}

.menu li {
	margin-left: 20px;
	font-size: 18px;
}

.menu li:first-child {
	margin-left: 0;
}

.menu a {
	transition: 0.2s all;
}

.menu a:hover {
	color: #1596dd;
}

.submit {
	color: #fff;
	display: block;
	background-color: #1596dd;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
	border: 1px solid #1596dd;
	border-radius: 8px;
}

.submit:hover {
	background-color: #fff;
	color: #1596dd;
}

.hamburger {
	display: none;
	flex-direction: column;
	justify-content: space-around;
	width: 30px;
	height: 24px;
	cursor: pointer;
}

.hamburger span {
	width: 100%;
	height: 3px;
	background-color: #000;
	transition: all 0.3s ease;
}

.hamburger.is-active span:nth-child(1) {
	transform: translateY(10px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
	opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
	transform: translateY(-10px) rotate(-45deg);
}

@media screen and (max-width: 650px) {
	.header {
		padding: 0 20px;
	}

	.logo img {
		width: 150px;
	}

	.menu {
		display: none;
		flex-direction: column;
		align-items: center;
		width: 100%;
		position: absolute;
		top: 80px;
		left: 0;
		background: #fff;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
		padding: 20px 0;
	}

	.menu.is-active {
		display: flex;
		align-items: flex-start;
		padding-left: 10px;
	}

	.menu li {
		margin-left: 0;
		margin-bottom: 10px;
		text-align: center;
	}

	.hamburger {
		display: flex;
	}
}

main {
	width: 100%;
	height: 100vh;
	background-image: url("../images/atf-background-desktop-image.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		.button a {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			border-radius: 8px;
			background: #1596dd;
			transition: all 0.2s ease;
			border: 1px solid #1596dd;
			&:hover {
				background: #fff;
				span {
					color: #1596dd;
				}
			}
			img {
				width: 100px;
				height: 100px;
				border-radius: 6px;
			}
			span {
				transition: all 0.2s ease;
				width: 200px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px;
				color: #fff;
				font-weight: 600;
				font-size: 24px;
			}
		}
	}
}

@media screen and (max-width: 620px) {
	// .logo {
	// 	display: none;
	// }
	// .header .menu {
	// 	width: 100%;
	// 	li:nth-child(1) {
	// 		order: 2;
	// 		margin-left: 50px;
	// 	}
	// 	li:nth-child(2) {
	// 		margin-left: 50px;
	// 		order: 3;
	// 	}
	// 	li:nth-child(3) {
	// 		order: 1;
	// 		margin-left: 0;
	// 	}
	//	}
}

@media screen and (max-width: 440px) {
	.update {
		display: flex;
		flex-direction: column;
		button {
			&:first-child {
				margin-bottom: 10px;
			}
			&:last-child {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.header {
		padding: 0 10px;
	}
	.menu li {
		margin-left: 10px;
	}
}
